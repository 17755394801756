import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { MaintenanceType } from 'core/lib/maintenance/types';

const path = `/api/maintenance`;

async function fetchMaintenance(path: string) {
    try {
        const response = await axios.get(path);
        return response.data as MaintenanceType;
    } catch (error) {
        throw new Error(error);
    }
}

export function useMaintenance(locale: string) {
    return useQuery<MaintenanceType, Error>({
        queryKey: [path, locale],

        queryFn: async () => fetchMaintenance(path),
    });
}
