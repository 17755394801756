import React from 'react';
import { Maintenance, MaintenanceType, LanguageContext } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { defineMessages } from '@formatjs/intl';
import { useIntl } from 'react-intl';
import TermsFooter from 'lib/shared/components/TermsFooter';
import { useMaintenance } from '../api/useMaintenance';
import { FullCenteredScreenLayout } from 'core/components/Layouts/FullCenteredScreen';

function MaintenanceContainer({
    Component,
    data,
}: {
    Component: () => JSX.Element;
    data: MaintenanceType;
}) {
    const { isMobile } = useWindowBreakpoints();

    return data?.disableConnection ? (
        <Maintenance
            data={data}
            Layout={FullCenteredScreenLayout}
            Footer={() => (
                <TermsFooter textColor={!isMobile ? 'white' : 'black'} />
            )}
        />
    ) : (
        <Component />
    );
}

const intlMessages = defineMessages({
    defaultDescription: {
        id: 'components.maintenance.default-description',
        defaultMessage:
            '<l>Votre espace assuré est temporairement indisponible.</l><l>Veuillez nous excuser pour la gêne occasionnée.</l>',
    },
});

const withMaintenance = (Component: (props?: any) => JSX.Element) => {
    return () => {
        const [data, setData] = React.useState<MaintenanceType>();
        const { formatMessage } = useIntl();
        const { locale } = React.useContext(LanguageContext);
        const { data: maintenanceData } = useMaintenance(locale);

        React.useEffect(() => {
            if (maintenanceData?.frontMaintenance) {
                setData({
                    disableConnection: true,
                    message: formatMessage(intlMessages.defaultDescription),
                });
                return;
            }

            setData(maintenanceData);
        }, [maintenanceData, formatMessage]);

        return (
            data ? (
                <MaintenanceContainer Component={Component} data={data} />
            ) : (
                <Component />
            )
        ) as any;
    };
};

export { withMaintenance, MaintenanceContainer };
