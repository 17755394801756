import { Flex, Spinner, Text, VStack } from '@chakra-ui/react';
import { LoginLogo } from 'core';
import { siteConfig } from 'lib/site';
import { FormattedMessage } from 'react-intl';

export default function OAuthRedirection() {
    const { client } = siteConfig;

    return (
        <Flex flexDirection="column" minH="50vh" justifyContent="space-between">
            <Flex flex="1" justifyContent="center">
                <LoginLogo logo={client.logo} />
            </Flex>
            <VStack spacing="6" flex="3">
                <Spinner size="lg" color="primary.main" />
                <Text p="2" textAlign="center">
                    <FormattedMessage
                        id="components.login.redirecting"
                        defaultMessage="Redirection en cours..."
                    />
                </Text>
            </VStack>
        </Flex>
    );
}
